import { subscriptionActions } from "../actions/subscription-actions";
import {
  defaultSubscriptionEntityState,
  defaultSubscriptionState,
} from "../state/subscription-state";
import { metaStatuses, defaultMetaState } from "../state/meta-state";

const handlers = {
  [subscriptionActions.FETCH_SUBSCRIPTION]: (state) => {
    return Object.assign({}, state, {
      entity: defaultSubscriptionEntityState,
      meta: {
        ...defaultMetaState,
        status: metaStatuses.LOADING,
      },
    });
  },

  [subscriptionActions.FETCH_SUBSCRIPTION_ERROR]: (state, payload) => {
    return Object.assign({}, state, {
      entity: defaultSubscriptionEntityState,
      meta: {
        status: metaStatuses.FAILURE,
        error: payload.message,
      },
    });
  },
  [subscriptionActions.FETCH_SUBSCRIPTION_SUCCESS]: (state, payload) => {
    const nextPayment = new Date(payload.current_period_end * 1000);

    // Function to get the day suffix (st, nd, rd, th)
    function getDaySuffix(day) {
      if (day >= 11 && day <= 13) return "th"; // Special case for 11th, 12th, 13th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    // Function to get the full month name
    function getMonthName(monthIndex) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthNames[monthIndex];
    }

    // Formatting the date
    const day = nextPayment.getDate();
    const daySuffix = getDaySuffix(day);
    const monthName = getMonthName(nextPayment.getMonth());
    const year = nextPayment.getFullYear();

    const formattedDate = `${day}${daySuffix} ${monthName} ${year}`;

    return Object.assign({}, state, {
      entity: {
        isActive: !payload.cancel_at_period_end,
        nextPayment: formattedDate,
      },
      meta: {
        ...defaultMetaState,
        status: metaStatuses.SUCCESS,
      },
    });
  },
};

const subscriptionReducer = (state = defaultSubscriptionState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default subscriptionReducer;
